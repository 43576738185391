import * as React from "react";
import { BannerContent } from "./BannerContent";
import lady from "./images/lady.png";
import city from "./images/city.png";

import styles from "./Banner.module.scss";

type IProps = {};

const Banner: React.FC<IProps> = () => {
  return (
    <>
      <div className={styles.Container}>
        <img src={lady} className={styles.Lady} alt="lady" />
        <img src={city} className={styles.City} alt="lady" />
        <BannerContent />
      </div>
    </>
  );
};

export { Banner };
