import { LinkProps } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { PaletteColorOptions } from "@mui/material/styles/createPalette";
import { AppLink } from "./common";

declare module '@mui/material/styles' {
    interface Theme {
        whiteColors: {
            white: string;
            white70: string;
            white80: string;
        };
        grayColors: {
            gray100: string;
            gray90: string;
            gray85: string;
            gray80: string;
            gray70: string;
            gray60: string;
            gray50: string;
            gray45: string;
            gray40: string;
            gray30: string;
            gray20: string;
            gray15: string;
        },
        blackColors: {
            black: string;
            black80: string;
        },
        goldColors: {
            gold200: string;
            gold100: string;
            gold50: string;
            gold20: string
        },
        redColors: {
            red100: string,
            red15: string
        },
        greenColors: {
            red100: string,
            red20: string
        },
        errorColor: string;
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        whiteColors?: {
            white?: string;
            white70?: string;
            white80?: string;
        };
        grayColors: {
            gray100?: string;
            gray90?: string;
            gray85: string;
            gray80?: string;
            gray70?: string;
            gray60?: string;
            gray50?: string;
            gray45?: string;
            gray40?: string;
            gray30?: string;
            gray20?: string;
            gray15?: string;
        },
        blackColors?: {
            black?: string;
            black80?: string;
        },
        goldColors?: {
            gold200?: string;
            gold100?: string;
            gold50?: string;
            gold20?: string
        },
        redColors?: {
            red100?: string,
            red15?: string
        },
        greenColors?: {
            red100?: string,
            red20?: string
        },
        errorColor?: string;
    }
}

export const whiteColors = {
    white: '#FFFFFF',
    white80: '#FFFFFF80',
    white70: '#FFFFFFB2',
}

export const blackColors = {
    black: '#000000',
    black80: '#131313',
}

export const goldColors = {
    gold200: '#AE934C',
    gold100: '#C4A659',
    gold50: '#DFC787',
    gold20: '#C4A65933',
}

export const grayColors = {
    gray100: '#333333',
    gray90: '#424242',
    gray85: '#505050',
    gray80: '#5B5B5B',
    gray70: '#626262',
    gray60: '#898989',
    gray50: '#AEAEAE',
    gray45: '#FFFFFF73',
    gray40: '#B3B3B34D',
    gray30: '#BABABA33',
    gray20: '#92929233',
    gray15: '#95959533',
}

export const redColors = {
    red100: '#DC3F3F',
    red15: '#DC3F3F26',
}

export const greenColors = {
    red100: '#5EA05D',
    red20: '#5EA05D33',
}

export const primaryColors: PaletteColorOptions = {
    main: goldColors.gold100,
    light: goldColors.gold50,
    dark: goldColors.gold200,
};

export const errorColors: PaletteColorOptions = {
    main: redColors.red100,
    light: '#e36565',
    dark: '#9a2c2c',
};

export const successColors: PaletteColorOptions = {
    main: greenColors.red100,
    light: '#7eb37d',
    dark: '#417041',
};

export const secondaryColors: PaletteColorOptions = {
    main: grayColors.gray85,
    light: grayColors.gray90,
    dark: grayColors.gray100,
};

const baseTheme = createTheme({
    palette: {
        primary: primaryColors,
        secondary: secondaryColors,
        error: errorColors,
        success: successColors,
        action: {
            disabledBackground: grayColors.gray90,
            disabled: grayColors.gray80
        },
    },
    typography: {
        fontFamily: 'Literata,"Helvetica","Arial",sans-serif',
    },
    whiteColors: {
        white: whiteColors.white,
        white70: whiteColors.white70,
        white80: whiteColors.white80,
    },
    grayColors: {
        gray100: grayColors.gray100,
        gray90: grayColors.gray90,
        gray85: grayColors.gray85,
        gray80: grayColors.gray80,
        gray70: grayColors.gray70,
        gray60: grayColors.gray60,
        gray50: grayColors.gray50,
        gray45: grayColors.gray45,
        gray40: grayColors.gray40,
        gray30: grayColors.gray30,
        gray20: grayColors.gray20,
        gray15: grayColors.gray15
    },
    blackColors: {
        black: blackColors.black,
        black80: blackColors.black80,
    },
    goldColors: {
        gold200: goldColors.gold200,
        gold100: goldColors.gold100,
        gold50: goldColors.gold50,
        gold20: goldColors.gold20
    },
    redColors: {
        red100: redColors.red100,
        red15: redColors.red15
    },
    greenColors: {
        red100: greenColors.red100,
        red20: greenColors.red20
    },
    errorColor: '#d32f2f'
})

export const defaultMaterialTheme = createTheme(baseTheme, {
    components: {
        MuiLink: {
            defaultProps: {
                component: AppLink,
            } as LinkProps,
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '12px',
                    fontWeight: '600',
                    borderRadius: 6,
                },
            },
        },
        MuiTableBody: {
            styleOverrides: {
                root: {
                    backgroundColor: baseTheme.grayColors.gray15,
                    "& .MuiTableRow-root:first-child": {
                        "& .MuiTableCell-root:first-child": {
                            borderTopLeftRadius: "8px",
                        },
                        "& .MuiTableCell-root:last-child": {
                            borderTopRightRadius: "8px",
                        }
                    },
                    "& .MuiTableRow-root:last-child": {
                        "& .MuiTableCell-root:first-child": {
                            borderBottomLeftRadius: "8px",
                        },
                        "& .MuiTableCell-root:last-child": {
                            borderBottomRightRadius: "8px",
                        }
                    },
                }
            }
        },
        MuiNativeSelect: {
            styleOverrides: {
                select: {
                    color: `${whiteColors.white}!important`
                },
                icon: {
                    color: baseTheme.whiteColors.white
                },
            }
        },
        MuiSelect: {
            styleOverrides: {
                select: {
                    color: baseTheme.whiteColors.white
                },
                icon: {
                    color: baseTheme.whiteColors.white
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: 0,
                    color: baseTheme.whiteColors.white
                },
                head: {
                    color: baseTheme.grayColors.gray70,
                    backgroundColor: `${baseTheme.blackColors.black80}!important`
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    color: baseTheme.whiteColors.white,
                    "&.Mui-selected": {
                        color: baseTheme.whiteColors.white,
                        backgroundColor: baseTheme.grayColors.gray15
                    }
                }
            }
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    "& svg": {
                        color: baseTheme.whiteColors.white70,
                    }
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    transformOrigin: ['right', 'top'],
                },
                paper: {
                    elevation: 0,
                    backgroundColor: baseTheme.grayColors.gray85,
                    color: baseTheme.whiteColors.white,
                    padding: '16px',
                    marginTop: '16px'
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    height: 48,
                    borderRadius: 8,
                    width: 200,
                    textWrap: 'wrap',
                    display: 'flex',
                    padding: 0,
                    paddingLeft: 10,
                    '& svg': {
                        color: baseTheme.palette.primary.light
                    }
                },
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    backgroundColor: baseTheme.grayColors.gray100,
                    color: baseTheme.grayColors.gray50,
                    marginRight: "12px"
                },
                deleteIcon: {
                    color: baseTheme.grayColors.gray80,
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: baseTheme.blackColors.black80,
                    color: baseTheme.whiteColors.white,
                    borderRadius: "8px"
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: "24px"
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: "24px 24px 20px"
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    backgroundColor: baseTheme.grayColors.gray20,
                    color: baseTheme.whiteColors.white70,
                    '&.Mui-disabled': {
                        color: baseTheme.grayColors.gray40,
                        '& input': {
                            textFillColor: baseTheme.grayColors.gray40,
                        }
                    },
                    '&.Mui-focused fieldset': {
                        borderWidth: "0px 0px 2px 0px !important",
                    }
                },
                notchedOutline: {
                    borderWidth: "0px",
                    borderBottom: "1px solid"
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: baseTheme.whiteColors.white70,
                    '&.Mui-disabled': {
                        color: baseTheme.grayColors.gray85
                    }
                }
            }
        },
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: baseTheme.whiteColors.white,
                    backgroundColor: baseTheme.grayColors.gray100,
                },
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                listbox: {
                    backgroundColor: `${baseTheme.grayColors.gray90}!important`
                },
                noOptions: {
                    backgroundColor: `${baseTheme.grayColors.gray90}!important`,
                    color: baseTheme.whiteColors.white,
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                icon: {
                    fill: `${baseTheme.whiteColors.white}!important`,
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    color: baseTheme.whiteColors.white,
                    backgroundColor: baseTheme.grayColors.gray85,

                    '.MuiPickersDay-root': {
                        color: baseTheme.whiteColors.white,
                        '&:not(.Mui-selected)': {
                            borderColor: baseTheme.whiteColors.white,
                        }
                    },
                    '.MuiClockNumber-root': {
                        color: baseTheme.whiteColors.white,
                        '&:not(.Mui-selected)': {
                            borderColor: baseTheme.whiteColors.white,
                        }
                    },
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: baseTheme.palette.primary.main
                }
            }
        }
    }
});