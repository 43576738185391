import * as React from "react";
import { ReactComponent as Footer1 } from "./images/footer1.svg";

import styles from "./Footer.module.scss";

type IProps = {};

const Footer: React.FC<IProps> = () => {
  return (
    <footer className={styles.Footer}>
      <Footer1 />
      <div className={styles.Text}>© 2024 stargamers.io</div>
    </footer>
  );
};

export { Footer };
