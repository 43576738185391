import * as React from "react";
import { ScrollToHashElement } from "./ScrollToHashElement";
import { Header } from "./Header";
import { Banner } from "./Banner";
import { PlayerSection } from "./PlayerSection";
// import { Iframe } from "./Iframe";
import { OrganizerSection } from "./OrganizerSection";
import { ModeratorSection } from "./ModeratorSection";
import { BotSection } from "./BotSection";
// import { FeedbackSection } from "./FeedbackSection";
// import { FAQSection } from "./FAQSection";
import { Footer } from "./Footer";

import styles from "./LandingScreen.module.scss";

type IProps = {};

const LandingScreen: React.FC<IProps> = () => {
  return (
    <>
      <div className={styles.Container}>
        <Header />
        <main className={styles.Main}>
          <div className={styles.ContentAling}>
            <Banner />
            {/* <Iframe /> */}
            <PlayerSection />
          </div>
          <div className={styles.Divider} />
          <div className={styles.ContentAling}>
            <OrganizerSection />
          </div>
          <div className={styles.Divider} />
          <div className={styles.ContentAling}>
            <ModeratorSection divider={<div className={styles.Divider} />} />
          </div>
          <div className={styles.Divider} />
          <div className={styles.ContentAling}>
            <BotSection />
          </div>
          {/* <div className={styles.Divider} />
          <div className={styles.ContentAling}>
            <FeedbackSection />
          </div>
          <div className={styles.Divider} />
          <div className={styles.ContentAling}>
            <FAQSection />
          </div> */}
        </main>
        <Footer />
      </div>
      <ScrollToHashElement />
    </>
  );
};

export { LandingScreen };
