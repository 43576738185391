import * as React from "react";
import moment from "moment/moment";
import { Snackbars } from "./common";
import { LandingScreen } from "./LandingScreen";

import "moment/locale/ru";
moment.locale("ru");

function App() {
  return (
    <>
      <LandingScreen />
      <Snackbars />
    </>
  );
}

export { App };
