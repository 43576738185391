import * as yup from "yup";

export const useResolver = <T>(validationSchema: yup.Schema<T>) =>
    async (data: T) => {
        try {
            const values = await validationSchema.validate(data, {
                abortEarly: false,
            })

            return {
                values,
                errors: {},
            }
        } catch (errors: any) {
            return {
                values: {},
                errors: errors.inner.reduce(
                    (allErrors: any, currentError: any) => ({
                        ...allErrors,
                        [currentError.path]: {
                            type: currentError.type ?? "validation",
                            message: currentError.message,
                        },
                    }),
                    {}
                ),
            }
        }
    }
