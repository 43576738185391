import * as React from "react";
import { ItemText } from "./ItemText";
import moderator1 from "./images/moderator1.png";
import moderator2 from "./images/moderator2.png";
import moderator3 from "./images/moderator3.png";

import styles from "./ModeratorSection.module.scss";

type IProps = {
  divider: JSX.Element;
};

const ModeratorSection: React.FC<IProps> = (props) => {
  const { divider } = props;

  return (
    <section className={styles.Section}>
      <h2 id="moderator" className={styles.Header}>
        Ведущему
      </h2>
      <div className={styles.Content}>
        <ItemText
          mainText="Кабинет с играми под управлением ведущего"
          secondText="Просматривайте активные, прошедшие игры"
          img={<img
            src={moderator1}
            className={styles.Moderator1Img}
            alt="cabinet"
          />}
        />
        {divider}
        <ItemText
          mainText="Страница с автоматизированным процессом ведения игры"
          secondText="Сконфигурированным организатором"
          img={<img
            src={moderator2}
            className={styles.Moderator2Img}
            alt="cabinet"
          />}
          revert
        />
        {divider}
        <ItemText
          mainText="Разбивка всего списка участников на отдельные столы"
          img={<img
            src={moderator3}
            className={styles.Moderator3Img}
            alt="table"
          />}
        />
      </div>
    </section>
  );
};

export { ModeratorSection };
