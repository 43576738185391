import * as React from "react";
import { List, Menu } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { ListItemlink } from "./ListItemlink";

import styles from "./Burger.module.scss";

const tabList = [
  {
    label: "Игрокам",
    to: "#players",
  },
  {
    label: "Организаторам",
    to: "#organizer",
  },
  {
    label: "Ведущему",
    to: "#moderator",
  },
  // {
  //   label: "FAQ",
  //   to: "#faq",
  // },
  {
    label: "Контакты",
    to: "#contacts",
  },
];

type IProps = {};

const Burger: React.FC<IProps> = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        className={styles.IconButton}
      >
        <MenuIcon />
      </div>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 30,
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: 0,
          horizontal: "right",
        }}
      >
        <List
          component={"nav"}
          sx={{ padding: 0, display: "flex", flexDirection: "column" }}
        >
          {tabList.map((tab) => (
            <ListItemlink key={tab.to} to={tab.to} label={tab.label} />
          ))}
        </List>
      </Menu>
    </>
  );
};

export { Burger };
