import { getApolloErrorMessage } from "./utils";
import { ApolloError } from "@apollo/client";

interface IDataDispatch {
    eventName: string;
    payload?: {
        message: string;
        key?: number;
    }
}

interface IDataEventListener {
    eventName: string;
    cb: (event: any) => void
}

interface IDataRemovetListener {
    eventName: string;
    cb: (event: any) => void
}

class EventsService {

    dispatch(data: IDataDispatch) {
        if (data.payload) {
            document.dispatchEvent(new CustomEvent(data.eventName, {
                detail: { message: data.payload.message, key: data.payload.key }
            }))
            return;
        }
        document.dispatchEvent(new CustomEvent(data.eventName))
    }

    addEventListener(data: IDataEventListener) {
        document.addEventListener(data.eventName, data.cb, false)

        return data.cb;
    }

    removeEventListener(data: IDataRemovetListener) {
        document.removeEventListener(data.eventName, data.cb, false)
    }
}

class EventsServiceSingelton {
    snackbarEventsService: EventsService | undefined;

    getEventsService () {
        if (!this.snackbarEventsService) {
            this.snackbarEventsService = new EventsService();
        }

        return this.snackbarEventsService;
    }
}

export const eventsService = new EventsServiceSingelton().getEventsService();

export const toastEventService = {
    showSuccess: (message: string) => {
        eventsService.dispatch({
            eventName: "successMessage",
            payload: { message, key: new Date().getTime() }
        });
    },
    showError: (message: string) => {
        eventsService.dispatch({
            eventName: "errorMessage",
            payload: { message, key: new Date().getTime() }
        });
    },
    showApolloError: (error: ApolloError) => {
        eventsService.dispatch({
            eventName: "errorMessage",
            payload: { message: getApolloErrorMessage(error), key: new Date().getTime() }
        });
    }
}