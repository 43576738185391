import * as React from 'react';
import { FormHelperText } from '@mui/material';
import classnames from 'classnames';
import { tss } from 'tss-react/mui';
import { Controller } from 'react-hook-form';
import { TextareaAutosize } from '@mui/base';

const useStyles = tss.create(({ theme }) => ({
    wrapper: {
        marginBottom: '10px',
    },
    textArea: {
        boxSizing: 'border-box',
        fontFamily: 'Literata, "Helvetica", "Arial", sans-serif',
        fontSize: '1rem',
        fontWeight: 400,
        lineHeight: 1.5,
        padding: '8px 12px',
        backgroundColor: '#92929233',
        color: 'rgba(255, 255, 255, 0.7)',
        borderWidth: '0px 0px 2px 0px',
        borderRadius: '4px',
        minHeight: '100px !important',
        maxHeight: '100px !important',
        width: '100% !important',
        '&::placeholder': {
            color: 'rgba(255, 255, 255, 0.7)',
            opacity: 1
        },
        '&:hover': {
            borderWidth: '0px'
        },
        '&:focus': {
            borderWidth: '0px 0px 2px 0px',
            borderColor: theme.goldColors.gold100
        },
        '&:focus-visible': {
            outline: 0
        }
    },
    error: {
        borderColor: theme.errorColor,
        '&:hover': {
            borderWidth: '0px 0px 2px 0px'
        },
        '&:focus': {
            borderColor: theme.errorColor,
        },
        '&::placeholder': {
            color: theme.errorColor
        }
    },
    helperText: {
        marginLeft: '14px',
        marginRight: '14px'
    }
}));

type IProps = {
    name: string;
    placeholder: string;
    error: string | undefined;
    control: any;
};

const TextAreaField: React.FC<IProps> = (props) => {
    const { classes } = useStyles();
    const { control, name, placeholder, error } = props;

    return <div className={classes.wrapper}>
        <Controller
            control={control}
            name={name}
            shouldUnregister={true}
            render={({ field }) => (
                <TextareaAutosize
                    placeholder={placeholder}
                    autoComplete='off'
                    minRows={3}
                    maxRows={3}
                    className={classnames(classes.textArea, !!error && classes.error)}
                    {...field}
                />
            )}
        />
        {!!error && <FormHelperText className={classes.helperText} error>{error}</FormHelperText>}
    </div>
};

export { TextAreaField };