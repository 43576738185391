import * as React from "react";
import { List } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ReactComponent as ClubIcon } from "./images/club.svg";
import { ReactComponent as ClubMob } from "./images/clubMob.svg";
import { ListItemlink } from "./ListItemlink";
import { MailForm } from "./MailForm";
import { Burger } from "./Burger";

import styles from "./Header.module.scss";

const tabList = [
  {
    label: "Игрокам",
    to: "#players",
  },
  {
    label: "Организаторам",
    to: "#organizer",
  },
  {
    label: "Ведущему",
    to: "#moderator",
  },
  // {
  //   label: "FAQ",
  //   to: "#faq",
  // },
  {
    label: "Контакты",
    to: "#contacts",
  },
];

type IProps = {};

const Header: React.FC<IProps> = () => {
  const theme = useTheme();

  return (
    <header className={styles.Container}>
      <ClubMob className={styles.ClubMob} />
      <ClubIcon className={styles.ClubDesktop} />
      <List component={"nav"} sx={{ padding: 0 }} className={styles.Nav}>
        {tabList.map((tab) => (
          <ListItemlink
            key={tab.to}
            to={tab.to}
            label={tab.label}
            marginRight={30}
          />
        ))}
      </List>
      <div className={styles.Right}>
        <a
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.text.primary,
            fontWeight: 600,
            fontSize: "12px",
          }}
          className={styles.Button}
          href="https://mafia.stargamers.io"
        >
          Найти игру
        </a>
        <MailForm />
        <Burger />
      </div>
    </header>
  );
};

export { Header };
