import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const ScrollToHashElement = () => {
  let location = useLocation();

  useEffect(() => {
    let hashElement;
    let hash = location.hash;

    const removeHashCharacter = (str: string) => {
      const result = str.slice(1);
      return result;
    };

    if (hash) {
      hashElement = document.getElementById(removeHashCharacter(hash));
    }

    if (hashElement) {
      hashElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [location.hash]);

  return null;
};

export { ScrollToHashElement };
