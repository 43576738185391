import ReactDOM from "react-dom/client";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  from,
  InMemoryCache,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { onError } from "@apollo/client/link/error";
import { App } from "./App";
import { defaultMaterialTheme } from "./theme";

import "./index.css";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const httpLink = createHttpLink({
  uri:
    process.env.NODE_ENV === "production"
      ? "https://stargamers.io/graphql"
      : "http://localhost:4000/graphql",
  credentials: "include",
});

const link = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      "Apollo-Require-Preflight": true,
    },
  };
});

const client = new ApolloClient({
  link: from([errorLink, link.concat(httpLink)]),
  cache: new InMemoryCache({}),
  connectToDevTools: true,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

document.fonts.load("16px Literata");
document.fonts.load("14px Literata");
document.fonts.load("12px Literata");

if ((document.getElementById("root") as HTMLElement).hasChildNodes()) {
  ReactDOM.hydrateRoot(
    document.getElementById("root") as HTMLElement,
    <ApolloProvider client={client}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  );
} else {
  root.render(
    <ApolloProvider client={client}>
      <ThemeProvider theme={defaultMaterialTheme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </ApolloProvider>
  );
}
