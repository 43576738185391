import * as React from 'react';
import { useEffect, useState } from "react";
import { MySnackbar } from "./MySnackbar";
import { SnackbarMessage } from "./interfaces";
import { eventsService } from "./EventsService";

type IProps = {}

export const Snackbars: React.FC<IProps> = () => {
    const [isOpenSuccess, setOpenSuccess] = useState(false);
    const [isOpenError, setOpenError] = useState(false);
    const [messageSuccess, setMessageSuccess] = useState<SnackbarMessage | undefined>(undefined);
    const [messageError, setMessageError] = useState<SnackbarMessage | undefined>(undefined);

    useEffect(() => {
       const cbSuccess = eventsService.addEventListener({
            eventName: 'successMessage',
            cb: (event: any) => {
                setOpenSuccess(true);
                setMessageSuccess(event.detail)
            }
        });
        
       const cbError = eventsService.addEventListener({
            eventName: 'errorMessage',
            cb: (event: any) => {
                setOpenError(true);
                setMessageError(event.detail)
            }
        });

        return () => {
            eventsService.removeEventListener({
                eventName: 'successMessage',
                cb: cbSuccess
            })

            eventsService.removeEventListener({
                eventName: 'errorMessage',
                cb: cbError
            })
        }
    }, [])

    return <>
        <MySnackbar text={messageSuccess?.message || ''} open={isOpenSuccess} key={messageSuccess?.key} severity={'success'} />
        <MySnackbar text={messageError?.message || ''} open={isOpenError} key={messageError?.key} severity={'error'} />
    </>
}