import * as React from 'react';
import { Snackbar } from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type IProps = {
    text: string;
    open: boolean;
    severity: 'success' | 'error' | 'warning' | 'info',
    onClose?:(data: boolean) => void;
};

const MySnackbar = (props: IProps) => {
    const { text, open, severity, onClose } = props;
    const [isOpen, setOpen] = React.useState(false);

    React.useEffect(() => {
        setOpen(open)
    }, [open]);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        onClose?.(false);
        setOpen(false);
    };


    return <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        autoHideDuration={3000}
        open={isOpen}
        onClose={handleClose}
    >
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {text}
        </Alert>
    </Snackbar>
}

export { MySnackbar };
