import * as React from "react";
import classNames from "classnames";
import player1 from "./images/player1.png";
import player3 from "./images/player3.png";
import player4 from "./images/player4.png";
import player5 from "./images/player5.png";
import { ReactComponent as ActiveGameIcon } from "./images/player6.svg";
import { ReactComponent as LocationGameIcon } from "./images/player7.svg";
import player8 from "./images/player8.png";
import { ReactComponent as HistoryGameIcon } from "./images/player9.svg";
import { ReactComponent as AvatarStatusIcon } from "./images/player10.svg";
import { ReactComponent as SelfRatingIcon } from "./images/player11.svg";
import { ReactComponent as OtherRatingIcon } from "./images/player12.svg";
import { ReactComponent as DescriptionIcon } from "./images/player13.svg";
import { ReactComponent as Player1Icon } from "./images/player14.svg";
import { ReactComponent as FutureGameIcon } from "./images/player15.svg";
import { Items } from "./Items";

import styles from "./PlayerSection.module.scss";

type IProps = {};

const PlayerSection: React.FC<IProps> = () => {

  return (
    <section className={styles.Section}>
      <h2 id="players" className={styles.Header}>
        Игрокам
      </h2>
      <div className={styles.Content}>
        <div className={styles.Cabinet}>
          <img src={player3} className={styles.Player3Img} alt="list" />
          <div className={styles.ItemsWrapper}>
            <div className={styles.ItemsHeader}>Личный кабинет игрока</div>
            <Items
              itemFirst={{
                firstIcon: <ActiveGameIcon />,
                firstText: "Список актуальных игр",
                secondIcon: <LocationGameIcon />,
                secondText: "Выбор игры по локации",
              }}
              itemSecond={{
                firstIcon: (
                  <img
                    src={player8}
                    className={styles.Player8Img}
                    alt="ratingPlayer"
                  />
                ),
                firstText: "Рейтинг игроков",
                secondIcon: <HistoryGameIcon />,
                secondText: "Удобный мониторинг истории игр",
              }}
            />
          </div>
        </div>
        <div className={styles.Achievement}>
          <div className={styles.AchievementItems}>
            <div className={styles.AchievementItemsDividerVertical} />
            <div className={styles.AchievementItem}>
              <AvatarStatusIcon />
              <div className={styles.AchievementItemText}>
                Выполняйте задания и улучшайте свой статус
              </div>
            </div>
            <div
              className={classNames(
                styles.AchievementItem,
                styles.AchievementItemSecond
              )}
            >
              <SelfRatingIcon />
              <div className={styles.AchievementItemText}>
                Побеждай и улучшай свой рейтинг в турнирной таблице
              </div>
            </div>
          </div>
          <img src={player1} className={styles.Player1Img} alt="achive" />
        </div>
        <div className={styles.Profile}>
          <img src={player4} className={styles.Player4Img} alt="profile" />
          <div className={styles.ProfileItems}>
            <div className={styles.ProfileHeader}>
              Возможность просматривать профайл любого игрока
            </div>
            <img src={player4} className={styles.Player5Img} alt="profile" />
            <div className={styles.ProfileItemWrapper}>
              <div className={styles.ProfileItem}>
                <OtherRatingIcon />
                <div className={styles.ProfileItemText}>
                  Отслеживайте рейтинг, количество игр у других игроков
                </div>
              </div>
              <div
                className={classNames(
                  styles.ProfileItem,
                  styles.ProfileItemSecond
                )}
              >
                <DescriptionIcon />
                <div className={styles.ProfileItemText}>
                  Изучай описание игроков подбирай интересных опонентов
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.Club}>
          <div className={styles.ClubItemsWrapper}>
            <div className={styles.ClubHeader}>
              Возможность просматривать страницу любого клуба
            </div>
            <div className={styles.ClubItems}>
              <div className={styles.ClubDividerVertical} />
              <div className={styles.ClubItem}>
                <Player1Icon />
                <div className={styles.ClubItemText}>
                  Просматривать рейтинг клуба
                </div>
              </div>
              <div
                className={classNames(styles.ClubItem, styles.ClubItemSecond)}
              >
                <FutureGameIcon />
                <div className={styles.ClubItemText}>
                  Просматривать предстоящие игры
                </div>
              </div>
            </div>
          </div>
          <img src={player5} className={styles.Player5Img} alt="club" />
        </div>
      </div>
    </section>
  );
};

export { PlayerSection };
