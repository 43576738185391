import { useMutation } from "@apollo/client";
import { graphql } from "../gql/gql";
import { useCallback } from "react";
import { toastEventService } from "../common/EventsService";

const ADD_FEEDBACK = /* GraphQL */ `
  mutation AddLandingFeedback($feedback: FeedbackInputDto!) {
    addLandingFeedback(feedback: $feedback)
  }
`;

const document = graphql(ADD_FEEDBACK);

export const useAddingFeeedback = () => {
  const [action, { data, loading, error }] = useMutation(document);

  const addFeeedback = useCallback(
    async (data: {
      comment: string;
      email: string;
      name: string;
      token: string;
    }) => {
      await action({
        variables: {
          feedback: data,
        },
        onError: (error) => {
          toastEventService.showApolloError(error);
        },
        onCompleted: () => {
          toastEventService.showSuccess("Сообщение отправлено");
        },
      });
    },
    [action]
  );

  return {
    addFeeedback,
    data,
    error,
    loading,
  };
};
