import * as React from "react";
import classNames from "classnames";

import styles from "./Items.module.scss";

type IProps = {
  itemFirst: {
    firstIcon: JSX.Element;
    firstText: string;
    secondIcon: JSX.Element;
    secondText: string;
  };
  itemSecond: {
    firstIcon: JSX.Element;
    firstText: string;
    secondIcon?: JSX.Element;
    secondText?: string;
  };
  classContainer?: string;
  showDivider?: boolean;
};

const Items: React.FC<IProps> = (props) => {
  const { itemFirst, itemSecond, classContainer, showDivider = true } = props;

  return (
    <div className={classNames(styles.ItemsContainer, classContainer)}>
      {showDivider && (
        <>
          <div className={styles.ItemsDividerVertical} />
          <div className={styles.ItemsDividerHorizontal} />
        </>
      )}
      <div className={classNames(styles.ItemWrapper, styles.ItemWrapperFirst)}>
        <div className={styles.Item}>
          {itemFirst.firstIcon}
          <div className={styles.ItemText}>{itemFirst.firstText}</div>
        </div>
        <div className={classNames(styles.Item, styles.ItemPosition)}>
          {itemFirst.secondIcon}
          <div className={styles.ItemText}>{itemFirst.secondText}</div>
        </div>
      </div>
      <div className={classNames(styles.ItemWrapper, styles.ItemWrapperSecond)}>
        <div className={styles.Item}>
          {itemSecond.firstIcon}
          <div className={styles.ItemText}>{itemSecond.firstText}</div>
        </div>
        {itemSecond.secondIcon && (
          <div className={classNames(styles.Item, styles.ItemPosition)}>
            {itemSecond.secondIcon}
            <div className={styles.ItemText}>{itemSecond.secondText}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export { Items };
