import * as React from "react";
import { ListItem, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

type Props = {
  to: string;
  label: string;
  marginRight?: number;
};

export const ListItemlink = (props: Props) => {
  const { to, label, marginRight = 0 } = props;
  const location = useLocation();
  const theme = useTheme();

  const CustomLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, any>((props, ref) => {
        const { ownerState, ...other } = props;
        return (
          <Link
            ref={ref}
            to={to}
            {...other}
            style={{ position: "relative", marginRight: `${marginRight}px` }}
          />
        );
      }),
    [to, marginRight]
  );

  return (
    <ListItem slots={{ root: CustomLink }}>
      <ListItemText
        primary={label}
        sx={{
          height: "45px",
          padding: 0,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: 0,
        }}
      />
      <span
        style={{
          position: "absolute",
          bottom: 0,
          height: "3px",
          width: "100%",
          backgroundColor: `${theme.palette.primary.main}`,
          display: location?.hash?.includes(to) ? "block" : "none",
        }}
      />
    </ListItem>
  );
};
