import * as React from "react";
import classNames from "classnames";

import styles from "./ItemText.module.scss";

type IProps = {
  mainText: string;
  secondText?: string;
  img: JSX.Element;
  revert?: boolean;
};

const ItemText: React.FC<IProps> = (props) => {
  const { mainText, secondText, img, revert } = props;

  return (
    <div
      className={classNames(styles.Container, revert && styles.ContainerRevert)}
    >
      {revert && img}
      <div className={styles.Text}>
        <div className={styles.MainText}>{mainText}</div>
        <div className={styles.SecondText}>{secondText}</div>
      </div>
      {!revert && img}
    </div>
  );
};

export { ItemText };
